import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import {
	Back,
	Button,
	Checkbox,
	File,
	Form,
	FormFooter,
	FormHeading,
	FormReview,
	FormStep,
	Input,
	Layout,
	MessageBox,
	Select,
} from '../components'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Controller, useForm } from 'react-hook-form'
import axios from 'axios'
import { API_ENDPOINT } from '../constants'
import SuccessIcon from '../img/success.svg'
import { AttachmentData } from '@sendgrid/helpers/classes/attachment'
import countries from '../data/countries.json'
import { pushToDataLayer } from '../helpers'

type RenewPageTemplateProps = {
	title: string
	countries: { name: string; intermediaries: { name: string; email: string }[] }[]
}

type FormData = {
	firstName: string
	surname: string
	contactNumber: string
	emailAddress?: string
	country: string
	intermediary: string
	policyNumber: string
	agree: boolean
	attachments: FileList
}

export type FormattedFormData = {
	firstName: string
	surname: string
	contactNumber: string
	emailAddress?: string
	country: string
	intermediary: { name: string; email: string }
	policyNumber: string
	agree: boolean
	attachments: AttachmentData[]
}

const RenewPageTemplate: React.FC<RenewPageTemplateProps> = ({ title }) => {
	const { register, handleSubmit, control, errors, formState, triggerValidation, getValues, setValue } = useForm<
		FormData
	>()

	const [intermediaries, setIntermediaries] = useState<string[]>([])
	const [files, setFiles] = useState<FileList>()
	const [step, setStep] = useState<number>(1)
	const [submitting, setSubmitting] = useState<boolean>(false)

	useEffect(() => {
		window.scrollTo({ top: 0 })
	}, [step])

	const onSubmit = handleSubmit(async (data) => {
		const { attachments, intermediary, ...rest } = data
		let formData: FormattedFormData = {
			...rest,
			intermediary: {
				name: intermediary,
				email:
					countries.find((cty) => cty.name === rest.country)?.intermediaries.find((int) => int.name === intermediary)
						?.email ?? '',
			},
			attachments: [],
		}

		formData.attachments = await Promise.all(
			Array.from(attachments).map(async (attachment) => {
				return new Promise<AttachmentData>((resolve, reject) => {
					const reader = new FileReader()
					reader.readAsDataURL(attachment)
					reader.addEventListener('load', () => {
						resolve({
							content: (reader.result as string).split(',')[1],
							filename: attachment.name,
							type: attachment.type,
						})
					})
					reader.addEventListener('error', (errors) => reject(errors))
				})
			}),
		)

		setSubmitting(true)

		try {
			await axios.post(`${API_ENDPOINT}/renew`, formData)
		} catch (e) {
			console.log(e)
		} finally {
			setSubmitting(false)
		}

		setStep(3)
	})

	const handleStep = (step: number) => async () => {
		await triggerValidation()

		if (Object.keys(errors).length === 0) {
			setStep(step)
		}
	}

	const setStatus = (fieldName: keyof FormData) => {
		if (errors[fieldName]) {
			return 'error'
		}

		if (formState.touched[fieldName] && errors[fieldName]) {
			return 'error'
		}

		if (formState.touched[fieldName]) {
			return 'successful'
		}
	}

	return (
		<div>
			<Form onSubmit={onSubmit}>
				<FormStep visible={step === 1}>
					<Back to={'/'} />
					<FormHeading>{title}</FormHeading>
					<Grid>
						<Input
							id={'first-name'}
							name={'firstName'}
							label={'First Name*'}
							placeholder={'First name'}
							title={'First name'}
							inputRef={register({ required: true })}
							message={errors.firstName && 'Please enter field'}
							status={setStatus('firstName')}
							onChange={() => triggerValidation('firstName')}
							onBlur={() => triggerValidation('firstName')}
						/>
						<Input
							id={'surname'}
							name={'surname'}
							label={'Surname*'}
							placeholder={'Surname'}
							title={'Surname'}
							inputRef={register({ required: true })}
							message={errors.surname && 'Please enter field'}
							status={setStatus('surname')}
							onChange={() => triggerValidation('surname')}
							onBlur={() => triggerValidation('surname')}
						/>
						<Input
							id={'number'}
							name={'contactNumber'}
							label={'Contact Number*'}
							placeholder={'+1'}
							title={'Contact number'}
							inputRef={register({ required: true, pattern: /[0-9 ]+/ })}
							message={errors.contactNumber && 'Please enter field'}
							status={setStatus('contactNumber')}
							onChange={() => triggerValidation('contactNumber')}
							onBlur={() => triggerValidation('contactNumber')}
						/>
						<Input
							id={'email'}
							name={'emailAddress'}
							label={'Email Address'}
							placeholder={'Email Address'}
							title={'Email address'}
							type={'email'}
							inputRef={register({
								pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
							})}
							status={setStatus('emailAddress')}
						/>
						<File
							id={'attachments'}
							name={'attachments'}
							title={'Attachments'}
							label={`Upload your Id or Passport and proof of address `}
							helper={`E.g. utility bill, bank statement`}
							ref={register}
							files={files}
							onChange={(e) => setFiles((e.target as any).files)}
							accept='image/*,.pdf'
						/>
						<Controller
							id={'country'}
							as={Select}
							name={'country'}
							control={control}
							label={'Country*'}
							placeholder={'Select country'}
							options={countries.map(({ name }) => name)}
							onChange={([{ value }]) => {
								setIntermediaries(
									countries.find((cty) => cty.name === value)?.intermediaries.map((int) => int.name) ?? [],
								)
								setValue('intermediary', '')
								return value
							}}
							message={errors.country && 'Please select a country'}
							rules={{ required: true }}
						/>
						<Controller
							id={'intermediary'}
							as={Select}
							name={'intermediary'}
							control={control}
							label={'Intermediary*'}
							placeholder={'Select intermediary'}
							options={intermediaries}
							onChange={([{ value }]) => {
								pushToDataLayer('intermediary', value)
								return value
							}}
							disabled={!getValues().country}
							message={errors.intermediary && 'Please select an intermediary'}
							rules={{ required: true }}
						/>
						<Input
							id={'policyNumber'}
							name={'policyNumber'}
							label={'Policy Number*'}
							placeholder={'Policy Number'}
							title={'Policy number'}
							inputRef={register({ required: true, pattern: /[0-9 ]+/ })}
							message={errors.policyNumber && 'Policy number is required'}
							status={setStatus('policyNumber')}
							onChange={() => triggerValidation('policyNumber')}
							onBlur={() => triggerValidation('policyNumber')}
						/>
					</Grid>
					<Controller
						as={Checkbox}
						control={control}
						rules={{ required: true }}
						name={'agree'}
						id={'agree'}
						labelText={
							<>
								I, the insured, hereby confirm acceptance of the terms of renewal in relation to the above referenced
								policies.
								<br />
								Please proceed to renewal.
							</>
						}
						checked={false}
						onChange={([
							{
								target: { checked },
							},
						]) => checked}
						message={'To continue please click the checkbox'}
						showMessage={!!errors.agree}
					/>
					<FormFooter>
						<Button
							type='button'
							variant='secondary'
							displayBlockOnMobile
							fixedSize={true}
							onClick={handleStep(2)}
							id='gtm-renew-continue'
						>
							Continue
						</Button>
					</FormFooter>
				</FormStep>
				<FormStep visible={step === 2}>
					<Back onClick={() => setStep(1)} />
					<FormHeading subheading='Please review details' center>
						Renew policy details
					</FormHeading>
					{step === 2 && (
						<FormReview fields={getValues()}>
							<Row center={'md'}>
								<Col xs md={8} style={{ textAlign: 'right' }}>
									<Button type='button' variant='transparent' underline onClick={() => setStep(1)} xp={0}>
										Edit
									</Button>
								</Col>
							</Row>
						</FormReview>
					)}
					<MessageBox>
						If the above information is correct, please proceed to submit. Alternatively, use the edit button to correct
						your submission before proceeding to submit.
					</MessageBox>
					<FormFooter>
						<Button type='submit' displayBlockOnMobile fixedSize={true} disabled={submitting} id='gtm-renew-submit'>
							{submitting ? 'Submitting...' : 'Submit'}
						</Button>
					</FormFooter>
				</FormStep>
				<FormStep visible={step === 3}>
					<Grid>
						<Row center='xs'>
							<Col>
								<SuccessIcon style={{ marginBottom: '1rem', marginTop: '1rem' }} />
							</Col>
						</Row>
					</Grid>
					<FormHeading
						subheading='Thank you.'
						center
						p={
							<>
								Your request for renewal of policy has been sent to {getValues().intermediary}.
								<br /> One of their representatives will be in touch with you soon.
							</>
						}
					>
						Renewal Request Submitted
					</FormHeading>
					<Grid>
						<Row center='xs'>
							<Col>
								<Button variant='secondary' type='button' fixedSize={true} as={Link} to={'/'} id='gtm-renew-finish'>
									Finish
								</Button>
							</Col>
						</Row>
					</Grid>
				</FormStep>
			</Form>
		</div>
	)
}

type RenewPageProps = {
	data: {
		markdownRemark: {
			frontmatter: RenewPageTemplateProps
		}
	}
}

const RenewPage: React.FC<RenewPageProps> = ({
	data: {
		markdownRemark: { frontmatter },
	},
}) => {
	return (
		<Layout useGrayBG padMain={false}>
			<RenewPageTemplate {...frontmatter} />
		</Layout>
	)
}

const pageQuery = graphql`
	query RenewPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "renew-page" } }) {
			frontmatter {
				title
			}
		}
	}
`

export default RenewPage
export { RenewPageTemplate, pageQuery }
